<!--台湾仓库异常退件-->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('退件管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('单号')" v-model="query.code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺名')" v-model="query.shopName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('标题')" v-model="query.title" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('规格')" v-model="query.spec" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('SKU')" v-model="query.sku" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('货架号')" v-model="query.shelfCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('归属人')" v-model="query.fullname" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('开始日期')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('结束日期')"
            size="small"
          >
        </el-date-picker>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar1" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="hasPermission('storeError:upper')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('新增退件')}}</span>
          </transition>
        </el-button>
        <!-- <el-button type="primary" size="mini" @click="edit()" v-if="hasPermission('storeError:edit')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('批量备注')}}</span>
          </transition>
        </el-button> -->
        <el-button type="primary" size="mini" @click="editShelfCode()" v-if="hasPermission('storeError:upper')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('更新货架号')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="resend()">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('转寄')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('storeError:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getStores(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="success" @click="queryVisiable = true;" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('批量查询')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('异常退件信息下载'),sheetName: 'Sheet1' })" >
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
     <!-- <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="full" size="small"  resizable  :data="stores" 
      align="center"
      :scroll-y="{enabled: false}"
      :span-method="mergeRowMethod"
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    > -->
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="full" size="small" resizable  :data="stores" 
      align="center"
      :scroll-y="{enabled: false}"
      :span-method="mergeRowMethod"
      highlight-hover-row 
    >
      <vxe-table-column type="checkbox" field="id" min-width="55" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')" min-width="40" :fixed="isMinScreen?null: 'left'"
         sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column field="code" :title="msg('单号')" min-width="120" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="id" :title="msg('货架号')" min-width="80" :show-overflow="true"
      >
        <template v-slot="{ row }">
          <span>{{row.shelfCode}}</span>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column field="shopOwner" :title="msg('店铺')" min-width="150" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column> -->
      <vxe-table-column field="productId" :title="msg('图片')" min-width="120">
        <template v-slot="{ row }">
          <el-image  style="width: 100px; height: 100px" :src="imgView+row.productId" :preview-src-list="[imgView+row.productId]"></el-image>
        </template>
      </vxe-table-column>
      <vxe-table-column field="shopName" :title="msg('店铺名')" min-width="120"
      >
        <template v-slot="{ row }">
          <span>{{row.shopName}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="orderName" :title="msg('商品标题')" min-width="200"
      >
        <template v-slot="{ row }">
          <span>{{row.orderName}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="orderSpec" :title="msg('规格')" min-width="120" :show-overflow="true"
      ></vxe-table-column>
      <vxe-table-column field="sku" :title="msg('SKU')" min-width="150" 
      >
        <template v-slot="{ row }">
          <span style="color:#2baf14;">{{row.sku}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="buyRemakr" :title="msg('买家备注')" min-width="120" 
      >
        <template v-slot="{ row }">
          <span style="color:#2baf14;">{{row.buyRemakr}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="qty" :title="msg('数量')" min-width="80" >
        <template v-slot="{ row }">
              <span style="font-size:20px;color:red;">{{row.qty}}</span>&nbsp;&nbsp;{{'件'}}
        </template>
      </vxe-table-column>
      <vxe-table-column field="status" :title="msg('状态')" min-width="100" 
        sortable
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.status]" disable-transitions size="medium" style="font-size:15px;">
            {{dicts.storeErrStatus[row.status]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="expOwner" :title="msg('归属人')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="created"  :title="msg('退件时间')" min-width="120"
        sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <!-- <vxe-table-column field="remark" :title="msg('备注')" min-width="40"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column> -->
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
  
  <el-dialog :title="msg('新增退件')" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" @opened="handleShelfOpen" width="400px">
    <store-err-code-edit :id="currId" :shelf="currShelf" v-on:closeDg="closeEditDialog" ref="shelfEdit"/>
  </el-dialog>

  <el-dialog :title="msg('批量备注')" :visible.sync="dialogTableVisibleRemark" :fullscreen="isMinScreen" @opened="handleShelfOpenRemark" width="500px">
    <store-err-remark :ids="remarkIds" v-on:closeDg="closeEditDialogRemark" ref="shelfEditRemark"/>
  </el-dialog>

  <el-dialog :title="msg('批量查询')" :visible.sync="queryVisiable" :fullscreen="isMinScreen"  width="450px" >
    <store-error-query ref="queryForm" @closeDg="doBatchQuery"/>
  </el-dialog> 

</div>
</template>

<script>
// @ is an alias to /src
import StoreErrCodeEdit from "./StoreErrCodeEdit.vue";
import StoreErrRemark from "./StoreErrRemark.vue";
import StoreErrorQuery from "./StoreErrorQuery.vue";
import XEUtils from 'xe-utils';

export default {
  name: 'store',
  components: {
    StoreErrCodeEdit : StoreErrCodeEdit,
    StoreErrRemark : StoreErrRemark,
    StoreErrorQuery:StoreErrorQuery
    },
  mounted:function(){
    this.getStores();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10, 
          Date:[],
          code:null,
          fullname:null,
          title:null,
          spec:null,
          sku:null,
          shelfCode:null,
          shopName:null,
        },
        ownerName: [],
        currentPage: 1,   //分页的当前页
        stores: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        currShelf:null,
        dialogTableVisible:false,
        dialogTableVisibleRemark:false,
        remarkIds:null,
        queryVisiable: false, //查询弹窗
        imgView: this.$kit.api.imgView,

    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        return this.$store.state.isMinScreen;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 320;
      }
    }
  },
  methods: {
    getStores: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      if(start){
        param.start = 0;
        this.currentPage = 1;
        this.queryType = null;
        this.queryCodes = null;
      }

      this.axios.post(this.$kit.api.twStoreErr.list, param).then(function (result) {
        if(result.status){
          if(_this.stores.length>0)
            _this.stores.splice(0, _this.stores.length);
          
          _this.total = result.data.recordsTotal;
          var datas = result.data.data;

          for(var i=0; i<datas.length; i++){
            var item = datas[i];
            _this.stores.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['id','code']
      const cellValue = XEUtils.get(row, column.property)
      if (cellValue && fields.includes(column.property) && visibleData) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.ownerName.splice(0,this.ownerName.length);
      this.getStores();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getStores();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getStores();
    },
    //批量查询
    doBatchQuery(data){
      this.queryVisiable = false;
      if(data){
        this.query['type'] = data.type;
        this.query['codes'] = data.codes;
        this.queryType = data.type;
        this.queryCodes = data.codes;
        this.getStores();
      }else{
        this.queryType = null;
        this.queryCodes = null;
      }
    },
    //入仓
    add(){
        this.isAdd = true;
        this.currId = null;
        this.currShelf = {
          shelfCode: null,
          expPageCode: null,
        };
        this.dglogTitle = this.msg("添加上架信息");
      
        this.dialogTableVisible = true;
    },
    //编辑
    edit(){
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var ids = selectes.map( o => o.id);

      this.remarkIds = ids;
      this.dialogTableVisibleRemark = true;

    },
    editShelfCode(){//更新货架号
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var ids = selectes.map( o => o.id);
      this.$prompt('请输入货架号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if(!value){
                    _this.$message(_this.msg('请输入货架号'));
                    return;
                }
                _this.$axios.post(_this.$kit.api.twStoreErr.updateShelfCode+"?ids="+ids+"&shelfCode="+value)
                .then((result) => {
                    if(result.status){
                        _this.getStores();
                        _this.$message(result.msg || _this.msg('更新成功'));
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
    },
    resend(){//转寄
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      let len = selectes.length;
      var ids = selectes.map( o => o.id);
      this.$confirm(this.msg('选择了 '+len+' 个订单，是否合并转寄？'), this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
          }).then(() => {
            _this.$prompt('请输入要转寄的订单编号', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                  }).then(({ value }) => {
                      if(!value){
                          _this.$message(_this.msg('请输入订单编号'));
                          return;
                      }
                      _this.$axios.post(_this.$kit.api.twStoreErr.resendCode+"?ids="+ids+"&code="+value)
                      .then((result) => {
                          if(result.status){
                              _this.getStores();
                              _this.$message(result.msg || _this.msg('转寄成功'));
                          }else{
                              _this.$message(result.msg || _this.msg('转寄失败'));
                          }
                      });
                  }).catch(() => {
                      _this.$message({
                          type: 'info',
                          message: '取消输入'
                      });       
                  });
          });
    },
    //删除
    deleted(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }

        var ids = selectes.map( o => o.id);
        var _this = this;

        this.$confirm(this.msg('确定要删除该异常退件信息吗?'), this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
          }).then(() => {
            _this.$axios.post(_this.$kit.api.twStoreErr.delete+"?ids="+ids)
              .then(function(result){
                _this.$message(result.msg || _this.msg('删除成功'));
                _this.getStores();
            });
          });
    },
    handleShelfOpen(){
      this.$nextTick(() => {
        this.$refs.shelfEdit.initForm(this.currShelf.shelfCode, this.isAdd)
      });
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      this.getStores();
      this.dialogTableVisible = false;
    },
    handleShelfOpenRemark(){
      this.$nextTick(() => {
        this.$refs.shelfEditRemark.initForm();
      });
    },
    closeEditDialogRemark(){
      this.getStores();
      this.dialogTableVisibleRemark = false;
      this.remarkIds = null;
    }
  }
}
</script>

<style class="scss" scoped>


</style>
