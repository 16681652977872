<!-- 上架 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('上架')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input :placeholder="msg('扫描快递单号')" ref="inputer1" @keyup.native="autoSearch" v-model.trim="innerCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="4">
      <el-input :placeholder="msg('架位号')" ref="inputer2" v-model.trim="shelfCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="6">
      <el-button type="success" @click="upper()" size="mini" style="margin-left: 10px; ">{{msg('上架')}}</el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <template>
    <el-table
      :data="tableData"
      border
      style="width: 50%">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="innerCode" label="快递单号" width="250"></el-table-column>
      <el-table-column prop="shelfCode" label="架位号" width="200"></el-table-column>
      <el-table-column prop="msg" label="结果"></el-table-column>
    </el-table>
  </template>
  </el-row>

  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
</div> 
</template> 

<script>
// @ is an alias to /src
import tixing from "../../../assets/media/1.wav";
export default {
  name: 'ExpPrintPage',
  components: {
  },
  mounted:function(){
  },
  data(){
    return{
      innerCode: null,
      shelfCode: null,
      tableData:[],
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    autoSearch(e){

        if(e.keyCode == 13){
            this.upper();
        }

    },

    upper(){

      if(!this.innerCode){
        this.$message(this.msg('请输入快递单号'));
        return;
      }
      if(!this.shelfCode){
        this.$message(this.msg('请输入架位号'));
        return;
      }
      
      this.axios.get(this.$kit.api.expUpper+'?innerCode='+this.innerCode+'&shelfCode='+this.shelfCode)
      .then((result) => {
        if(result.status){
          var datas = result.data;
          this.tableData.push({
            innerCode:datas.innerCode,
            shelfCode:datas.shelfCode,
            msg:datas.msg,
          })
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          if(datas.msg.indexOf('成功') == -1){
            this.playAudio();
          }
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = tixing;
        audio.play();
    },
  }
   
  
}
</script>

<style>
.el-col-lg-6 {
    width: 22%;
}
</style>
