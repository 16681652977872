<!-- 添加异常退件上架信息 -->
<template>
<el-form ref="form" :model="shelf" label-width="60px">
    <el-form-item :label="msg('上架码')">
        <el-input v-model="pageCode" ref="codeRef" @keyup.native="autoSave"></el-input>
    </el-form-item>
    <!-- <el-form-item :label="msg('上架码')" v-if="isAdd && openAutoSave" @keyup.native="autoSave">
        <el-input v-model="pageCode"></el-input>
    </el-form-item> -->
    <el-form-item :label="msg('货架号')">
        <el-input v-model="shelfCode" placeholder="可不填"></el-input>
    </el-form-item>
    <!-- <el-form-item :label="msg('自动保存')">
        <el-switch v-model="openAutoSave " v-if="isAdd" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
    </el-form-item> -->
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" @click="closeDialog()">{{msg('取消')}}</el-button>
    </div>
    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
</el-form> 
</template>

<script>
import ok from "../../../assets/media/ok.mp3";//成功
import err from "../../../assets/media/cancel.mp3";//成功
export default {
  name: 'StoreEdit',
  data(){
    return{
       code: null,
       pageCode: null,
       prevPageCode: null,
       isAdd: false,
       openAutoSave: false,
       inputing: false,
       shelfCode:null,
       audioUrl:null,
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      },
      scanPageCode:{
          get(){
              return this.pageCode;
          },
          set(val){
              this.prevPageCode = this.pageCode;
              this.pageCode = val;
          }
      }
  },
  props:{
      id:null,
      shelf:Object,
  },
  mounted: function(){
        var _this = this;
       
  },
  methods:{
    initForm(code, hasAdd){
        this.code = code;
        this.isAdd = hasAdd;
        this.$refs['codeRef'].focus();
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
    onSubmit(){

        if(!this.pageCode){
            this.$message("上架码不能为空");
            return;
        }

        // if(!this.shelfCode){
        //     this.$message("货架号不能为空");
        //     return;
        // }

        let store = {
            code:this.pageCode,
            shelfCode:this.shelfCode
        }

        var _this = this;
        this.$axios.post(this.$kit.api.twStoreErr.upper,store)
            .then(function(result){
                if(result.status){
                   _this.$message(result.msg || _this.msg("创建成功"))
                    this.audioUrl = ok;
                    this.playAudio();
                    this.pageCode = null;
                    _this.$refs['codeRef'].focus();
                }else{
                    _this.$message(result.msg || _this.msg('创建失败'));
                    this.audioUrl = err;
                    this.playAudio();
                    this.pageCode = null;
                    _this.$refs['codeRef'].focus();
                }
               
            }).catch(function (error) {
                console.error(error);
            });

    },
    closeDialog(type){
        this.pageCode = null;
        this.$emit("closeDg", type);
    },
    autoSave(e){
        if(e.keyCode == 13){
            this.doAutoSave();
        }
        // if(this.inputing == false){
        //     this.inputing = true;
        //     var _this = this;
        //     var timer = setInterval(() => {
                
        //         if(_this.prevPageCode == _this.pageCode){
        //             clearInterval(timer);
        //             _this.doAutoSave();
        //             _this.inputing = false;

        //         }else{
        //             _this.prevPageCode = _this.pageCode;
        //         }

        //     }, 500);
        // }
    },
    doAutoSave(){
        var _this = this;
        if(!this.pageCode){
            this.$message("上架码不能为空");
            return;
        }

        // if(!this.shelfCode){
        //     this.$message("货架号不能为空");
        //     return;
        // }

        let store = {
            code:this.pageCode,
            shelfCode:this.shelfCode
        }

        this.$axios.post(this.$kit.api.twStoreErr.upper,store)
            .then((result)=>{
                if(result.status){
                    _this.$message(result.msg || _this.msg("创建成功"))
                    this.audioUrl = ok;
                    this.playAudio();
                    this.pageCode = null;
                    _this.$refs['codeRef'].focus();
                }else{
                    _this.$message(result.msg || _this.msg('创建失败'));
                     this.audioUrl = err;
                    this.playAudio();
                    this.pageCode = null;
                    _this.$refs['codeRef'].focus();
                }
            }).catch(function (error) {
                console.error(error);
                _this.pageCode = null;
            });
    }
  },
  
}
</script>

<style class="scss" scoped>


</style>
