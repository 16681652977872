<template>
<el-form ref="form" :model="balance" label-width="80px">
    <el-alert style="margin-bottom: 20px;" :title="msg('首次使用请复制下面代码到浏览器网址开启端口，请使用Chrome内核的浏览器')"
        description="chrome://flags/#enable-experimental-web-platform-features" 
        type="warning">
    </el-alert>
    <el-form-item :label="msg('COM端口')">
        <el-input v-model="balance.port" :placeholder="msg('输入COM端口')"></el-input>
    </el-form-item>
    <el-form-item :label="msg('表达式')">
        <el-input v-model="balance.expression" type="textarea" :rows="5" :placeholder="msg('请输入取值表达式')"></el-input>
    </el-form-item>
    <el-alert style="margin-bottom: 10px;" title="取值表达式使用js代码， ${value}表示从电子秤读取到的数据">
                        <template>
                            <pre>
(function(){   
    var val = "${value}";  //耀华读取到的数据为： =342.000=345.000=344.000
    val = val.split("=");
    val = val[val.length-2];
    return (val.split("").reverse().join(""))*1;  //这里就读取到最后一个值并转换成0.443
})(); 
                            </pre>
                        </template>
                    </el-alert>
    <el-alert style="margin-bottom: 20px;" title="如果您不知道从电子秤读取到的数值是什么样子，请下载测试工具进行测试">
        <template>
        <!-- <a :href="comTest">点击下载</a> -->
        <el-link type="success" :href="comTest">点击下载</el-link>
        </template>
    </el-alert>
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'lanEdit',
  data(){
    return{
        comTest: this.$kit.api.comTest,
        balance:{
            port:null,
            expression:null,
            owner:null,
            id:null,
        },
    } 
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      }
  },
  props:{
  },
  methods:{
    init(){
        var _this = this;
        this.$axios.post(this.$kit.api.balance.get)
            .then(function(result){
                if(result.status){
                    let data = result.data;
                    _this.balance = {
                        port:data.port,
                        expression:data.expression,
                        owner:data.owner,
                        id:data.id,
                    }
                }else{
                    _this.balance = {
                        port:null,
                        expression:null,
                        owner:null,
                        id:null,
                    }
                }
               
            });
    },
    onSubmit(){

        var url = null;
        if(this.balance.id==null){
            url = this.$kit.api.balance.create;
        }else{
            url = this.$kit.api.balance.update;
        }
        var _this = this;
        this.$axios.post(url, this.balance)
            .then(function(result){
                if(result.status){
                    _this.$emit("closeDg", true);
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
