<!-- 订单扫描 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('订单扫描')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input :placeholder="msg('扫描单号')" ref="inputer1" @keyup.native="autoSearch" v-model.trim="innerCode" size="small">
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="12">
      <el-button type="success" @click="upper()" size="mini" style="margin-left: 10px; ">{{msg('确认')}}</el-button>
      <el-button type="danger" @click="clearTable()" size="mini" style="margin-left: 10px; ">{{msg('清空列表')}}</el-button>
      <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('订单扫描记录'),sheetName: 'Sheet1' })">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
    </el-col>
  </el-row>
  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="8">
      <div style="white-space:nowrap;">
          <span class="weight-title">{{msg('重量：')}}</span>
          <input class="weight-inputer" v-model="weight" />
          <span class="weight-unit">{{msg('KG')}}</span>
      </div>
    </el-col>
    <el-col :xs="24" :sm="10">
      <el-button type="success" @click="connectBalance()" size="mini" style="margin-left: 10px;margin-top:10px; ">{{msg('连接电子秤')}}</el-button>
      <el-button type="danger" @click="disConnectBalance()" size="mini" style="margin-left: 10px;margin-top:10px; ">{{msg('断开连接')}}</el-button>
      <el-button type="primary" @click="balanceSetting()" size="mini" style="margin-left: 10px;margin-top:10px; ">{{msg('电子秤设置')}}</el-button>
    </el-col>
  </el-row>
  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input :placeholder="msg('请输入包号，只能是数字和字母')" ref="inputer2"  v-model.trim="pkgCode" size="small">
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="12">
      <el-button type="success" @click="savePkgCode()" size="mini" style="margin-left: 10px; ">{{msg('保存包号')}}</el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <template>
    <!-- <el-table
      :data="tableData"
      border
      max-height="800"
      height="800"
      style="width: 60%;">
      <el-table-column prop="code" label="单号" width="300"></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column prop="created" label="操作时间" width="300"></el-table-column>
    </el-table> -->
    <vxe-table style="width: 80%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="tableData" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')" width="70" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="code" :title="msg('单号')" min-width="150" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="status" :title="msg('状态')" min-width="100" 
          sortable 
      >
      </vxe-table-column>  
      <vxe-table-column field="created" :title="msg('操作时间')" min-width="150" 
      ></vxe-table-column> 
      <vxe-table-column field="pkgCode" :title="msg('包号')" min-width="150" 
      ></vxe-table-column>    
      <vxe-table-column field="weight" :title="msg('重量')" min-width="150" 
      ></vxe-table-column>  
      <vxe-table-column :title="msg('操作')"  min-width="80" sortable>
        <template v-slot="{ row }">
          <div><span><el-button type="danger" size="mini" @click="deleteRow(row)">{{msg('删除')}}</el-button></span></div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </template>
  </el-row>

  <el-dialog title="电子秤设置" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" width="600px" @opened="hendleOpenedEdit()">
    <edit v-on:closeDg="closeEditDialog" ref="balanceEdit"/>
  </el-dialog>

  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
</div> 
</template> 

<script>
// @ is an alias to /src
import tixing from "../../assets/media/1.wav";
import cancel from "../../assets/media/cancel.mp3";
import chongfu from "../../assets/media/chongfu.mp3";
import wshuju from "../../assets/media/wshuju.mp3";
import edit from "./CheckStatusEdit.vue";
import { ComPort } from "../../components/ComPort";
export default {
  name: 'ExpPrintPage',
  components: {
    edit:edit
  },
  mounted:function(){
    this.getBalance();
  },
  data(){
    return{
      innerCode: null,
      tableData:[],
      pkgCode:null,
      weight:null,
      dialogTableVisible:false,
      balance:null,
      ecCom: null, //com端口对象
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    getBalance(){
        var _this = this;
        this.$axios.post(this.$kit.api.balance.get)
              .then(function(result){
                  if(result.status){
                      let data = result.data;
                      _this.balance = {
                          port:data.port,
                          expression:data.expression+"",
                          owner:data.owner,
                      }
                  }
              });
    },
    autoSearch(e){

        if(e.keyCode == 13){
            this.upper();
        }

    },

    upper(){

      if(!this.innerCode){
        this.$message(this.msg('请输入单号'));
        return;
      }

      if(this.tableData.length > 600){
        this.tableData = [];
        this.$message(this.msg('数量超过600，请先清空数据'));
        return;
      }

      let ok = true;
      if(this.tableData.length > 0){
        this.tableData.forEach(e => {
            if(e.code == this.innerCode){
                ok = false;
            }
        });
      }
      if(!ok){
          this.playAudio(chongfu);
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          return;
      }

      this.axios.get(this.$kit.api.expCheckStatus+'?code='+this.innerCode+'&weight='+this.weight*1000)
      .then((result) => {
        if(result.status){
          var data = result.data;
          let status = this.dicts.expStatus[data.status];
          this.tableData.splice(0,0,{
              id:data.id,
              code:data.orderCode,
              status:status,
              created:data.created,
              pkgCode:data.pkgCode,
              weight:data.weight.toFixed(2)
          })
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          if(status.indexOf('取消') > -1){
            this.playAudio(cancel);
          }else{
            this.playAudio(tixing);
          }
        }else{
          this.innerCode = null;
          this.playAudio(wshuju);
          this.$refs['inputer1'].focus();
          this.$message(result.msg || this.msg('单号不存在'));
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },
    savePkgCode(){
      if(!this.pkgCode){
        this.$message(this.msg('请输入包号！！'));
        return;
      }
      let regx = /^[0-9a-zA-Z]*$/;
      if(!regx.test(this.pkgCode)){
          this.$message(this.msg('包号只能是字母和数字，请重新输入！'));
          return;
      }
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var ids = selectes.map( o => o.id);

      var _this = this;
      this.$confirm(this.msg('确定要保存包号吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.savePkgCode+'?pkgCode='+_this.pkgCode, ids)
            .then(function(result){
              if(result.status){
                _this.$message(result.msg || _this.msg('保存成功'));
                _this.innerCode = null;
                _this.pkgCode = null;
                _this.$refs['inputer1'].focus();
                _this.tableData.splice(0,_this.tableData.length);
              }else{
                  _this.pkgCode = null;
                  _this.$refs['inputer2'].focus();
                  _this.$message(result.msg || _this.msg('保存失败'));
              }
          });
        });
    },
    clearTable(){
      this.tableData = [];
    },
    playAudio(src){
        let audio = this.$refs.audioRef;
        audio.src = src;
        audio.play();
    },
    deleteRow(row){
      for (let i = 0; i < this.tableData.length; i++) {
        const e = this.tableData[i];
        if(e.id == row.id){
          this.tableData.splice(i,1);
        }
      }
    },
    hendleOpenedEdit(){
      this.$nextTick(() => {
        this.$refs.balanceEdit.init();
      });
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      this.dialogTableVisible = false;
    },
    balanceSetting(){
      this.dialogTableVisible = true;
    },
    connectBalance(){ //连接电子秤

      this.getBalance();
      
      var _this = this;

        if(this.balance){
      
            if(this.ecCom){
                this.$message("已连接，请断开后再试");
                return;
            }
      
            this.ecCom = new ComPort();
            this.ecCom.connect(this.balance.port*1);
            this.ecCom.addEventListener("rx", (e)=>{ _this.readFromCom(e); });
            this.ecCom.addEventListener("log",(e)=>{ _this.readLogFromCom(e); });
        }else{
           this.$message(this.msg('请先进行设置'));
           return;
        }
    },
    disConnectBalance(){ //断开连接
      if(this.ecCom){
          this.ecCom.disconnect();
          this.ecCom = null;
      }
    },
    readLogFromCom(e){
        // console.log(e);
        if(e.detail){
            this.$message(e.detail);
        }
    },
    readFromCom(e){ //从电子秤读取数据的回调
        var _this = this;
        if(e.detail){
            var temp = e.detail.replace(/[\r\n]/g, ",").replace(/'/g, "");
            console.log(temp);
            if(_this.balance.expression){
                let express = _this.balance.expression;
                if(typeof express === 'function'){
                    _this.weight=express(temp);
                }else{
                    try {
                        _this.weight=eval(express.replace("${value}", temp));
                    }catch(err){
                        console.error(err);
                    }
                }
            }
        }
    },
  }
   
  
}
</script>

<style>
.el-col-lg-6 {
    width: 22%;
}
.weight-item /deep/ .el-form-item__label{
    margin-top:17px;
    margin-left:15px;
    font-size:22px; 
    position:relative;
}
.weight-inputer{
    background: black;
    color: white;
    border:none;
    outline: none;
    padding: 5px; 
    font-size:26px;
    font-weight:bold;
    width: 200px;
}
.weight-title{
    display:inline-block;
    position:relative;
    font-size:22px; 
}
.weight-unit{
    display:inline-block;
    position:relative;
    font-size:26px; 
    margin-left: 5px;
    top: 2px;
}
</style>
