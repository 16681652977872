<!-- 一键发货扫描 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('扫码一键发货')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input style="margin-top:15px;" :placeholder="msg('请扫描包号')" ref="inputerPkgCode" @keyup.native="autoSearchPkgCode" v-model.trim="pkgCode" size="small">
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="6">
      <el-button type="success" @click="searchPkgCode()" size="mini" style="margin-left: 10px; ">{{msg('查询')}}</el-button>
      <span style="margin-left:20px;margin-top:20px;color:#F56C6C">此包号含<span style="font-size:30px"> {{expCount}} </span>个小号</span>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input :placeholder="msg('请扫描单号，扫描包号无效')" ref="inputer1" @keyup.native="autoSearch" v-model.trim="innerCode" size="small">
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="6">
      <el-button type="success" @click="upper()" size="mini" style="margin-left: 10px; ">{{msg('确认')}}</el-button>
      <el-button type="success" @click="clearTable()" size="mini" style="margin-left: 10px; ">{{msg('清空列表')}}</el-button>
      <el-button type="danger" @click="batchSend()" size="mini" style="margin-left: 10px; ">{{msg('一键发货')}}</el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input type="textarea" :rows="4" :placeholder="msg('批量录入单号')" ref="inputer2" v-model="innerCodes" size="small">
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="6">
      <el-button type="success" @click="batchUpper()" size="mini" style="margin-left: 10px; ">{{msg('批量保存')}}</el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <template>
    <el-table
      :data="tableData"
      border
      max-height="800"
      height="800"
      style="width: 50%">
      <el-table-column type="index" label="序号" width="80"></el-table-column>
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column prop="code" label="单号"></el-table-column>
      <el-table-column
      fixed="right"
      label="操作"
      width="150">
      <template slot-scope="scope">
        <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="danger" size="small">移除</el-button>
      </template>
    </el-table-column>
    </el-table>
  </template>
  </el-row>

  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
</div> 
</template> 

<script>
// @ is an alias to /src
import tixing from "../../assets/media/1.wav";
import no from "../../assets/media/no.mp3";
import send from "../../assets/media/send.mp3";
export default {
  name: 'ExpPrintPage',
  components: {
  },
  mounted:function(){
  },
  data(){
    return{
      innerCode: null,
      innerCodes:null,
      pkgCode:null,
      expCount:0,
      tableData:[],
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    deleteRow(index, rows) {
        rows.splice(index, 1);
      },
    autoSearch(e){

        if(e.keyCode == 13){
            this.upper();
        }

    },
    autoSearchPkgCode(e){

        if(e.keyCode == 13){
            this.searchPkgCode();
        }

    },

    upper(){

      if(!this.innerCode){
        this.$message(this.msg('请输入单号'));
        return;
      }

      if(this.tableData.length >= 300){
        this.playAudio(send);
        return;
      }
      
      this.axios.get(this.$kit.api.batchSendScan+'?code='+this.innerCode)
      .then((result) => {
        if(result.status){
          var datas = result.data;
          datas.forEach(e => {
              this.tableData.push({
                id:e.id,
                code:e.orderCode
              })
          });
          for (let i = 0; i < this.tableData.length; i++) {
              for (let j = i+1; j < this.tableData.length; j++) {
                  if(this.tableData[i].code == this.tableData[j].code){
                      this.tableData.splice(j,1);
                      j--;
                  }
              }
          }
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          this.playAudio(tixing);
        }else{
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          this.playAudio(no);
          this.$message(result.msg || this.msg('单号不存在'));
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },
    batchUpper(){
      if(!this.innerCodes){
            this.$message(this.msg('请输入单号'));
            return;            
        }

        let cs = this.innerCodes.split(/\s+/);
        
        let css = [];
        for(let i=0; i<cs.length; i++){
            if(cs[i]) css.push(cs[i]);
        }
        
        if(css.length==0){
            this.$message(this.msg('请输入有效数据'));
            return;
        }
        this.axios.get(this.$kit.api.batchSendScans+"?codes="+css.join(","))
          .then((result) => {
            if(result.status){
              var datas = result.data;
              datas.forEach(e => {
                  this.tableData.push({
                    id:e.id,
                    code:e.orderCode
                  })
              });
              for (let i = 0; i < this.tableData.length; i++) {
                  for (let j = i+1; j < this.tableData.length; j++) {
                      if(this.tableData[i].code == this.tableData[j].code){
                          this.tableData.splice(j,1);
                          j--;
                      }
                  }
              }
              this.innerCodes = null;
              this.$refs['inputer2'].focus();
              this.playAudio(tixing);
            }else{
              this.innerCodes = null;
              this.$refs['inputer2'].focus();
              this.playAudio(no);
              this.$message(result.msg || this.msg('单号不存在'));
            }
            
          }).catch(function (error) {
            console.error(error);
          });
    },
    searchPkgCode(){
      if(!this.pkgCode){
        this.$message(this.msg('请输入包号'));
        return;
      }
      this.axios.get(this.$kit.api.searchExpCodeByPkgCode+'?pkgCode='+this.pkgCode)
      .then((result) => {
          if(result.status){
              this.expCount = result.data;
              this.$refs['inputer1'].focus();
              this.$message(this.msg('查询成功'));
          }else{
              this.$message(this.msg('单号不存在'));
          }
      })
    },
    clearTable(){
      this.tableData = [];
    },
    playAudio(src){
        let audio = this.$refs.audioRef;
        audio.src = src;
        audio.play();
    },
    batchSend(){
      if(this.tableData.length == 0){
          this.$message(this.msg('请输入单号'));
          return;
      }
      var _this = this;
      let ids = this.tableData.map(e => e.id).join(",");
      let codes = this.tableData.map(e => e.code).join(",");
      this.$confirm(_this.msg('确定要一键发货吗?'), _this.msg('提示'), {
          confirmButtonText: _this.msg('确定'),
          cancelButtonText: _this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.saveCodeList(codes);
          _this.axios.get(_this.$kit.api.batchSend+"?ids="+ids).then((result) => {
            if(result.status){ 
              _this.tableData = [];
            }
            _this.$message(result.data);
          }).catch(function (error) {
              console.error(error);
          });
        });
    },
    saveCodeList(codes){
      var _this = this;
      _this.axios.get(_this.$kit.api.scanSaveCode+"?codes="+codes)
        .then((result) => {
          }).catch(function (error) {
              console.error(error);
          });
    }
  }
   
  
}
</script>

<style>
.el-col-lg-6 {
    width: 22%;
}
</style>
