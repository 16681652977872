<!--编辑异常退件备注信息-->
<template>
<el-form ref="form" :rules="rules" label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="22">
            <el-form-item :label="msg('备注信息')" :prop="remark">
                <el-input v-model="remark" type="textarea" :rows="6"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <div style="text-align:center;padding-top: 20px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        remark:null,
        rules: {
            remark: [
                { required: true, message: '请输入备注信息', trigger: 'blur' },
            ],
       }
    }
  }, 
  computed:{
  },
  props:{
      ids:Array,
  },
  mounted: function(){
        var _this = this;
  },
  methods:{
    initForm(){
        this.remark = null;
    },
    onSubmit(){

        var _this = this;

        if(!this.remark){
            _this.$message(this.msg('请输入备注信息'));
            return;
        }

        this.$axios.post(this.$kit.api.twStoreErr.update+"?ids="+this.ids.map(e => e)+"&remark="+this.remark)
            .then(function(result){
                if(result.status){
                     _this.$message(result.msg || _this.msg('更新成功'));
                     _this.$emit("closeDg", true);
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            });

    }
  }
}
</script>

<style class="scss" scoped>
.el-form-item{
    margin-bottom: 0;
}

</style>
