<!-- 台灣庫存導入--台灣操作 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('台灣庫存導入')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
        <el-upload class="upload-demo" :with-credentials="true" multiple
            :file-list="fileList"
            :action="uploadWeight" :on-success="handleSuccess">
              <el-button size="small" icon="el-icon-upload2" type="primary">{{msg('导入')}}</el-button>
        </el-upload>
    </el-col>
    <el-col :span="12" style="text-align:right;">
        <el-button type="success" icon="el-icon-download" @click="downloadTpl()" size="small">{{msg('模板下载')}}</el-button>
    </el-col>
  </el-row>

  <div class="table-wrap">
    <el-table :data="results" style="width: 100%" height="400px" size="mini" ref="dataTable" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="code" :label="msg('寄出單號')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="productName" :label="msg('品名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="productTitle" :label="msg('貨物代號')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="productSpec" :label="msg('規格')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="productQty" :label="msg('數量')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" :label="msg('歸屬人')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="result" :label="msg('上传结果')" min-width="200"></el-table-column>
    </el-table>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert title="请使用模版上传，默认只读取第一个sheet"  type="warning"></el-alert>
        <!-- <el-alert title="类型只能为：黑貓、新竹、蝦皮、蝦皮打單、蝦皮過刷、回倉、拆袋"  type="error"></el-alert> -->
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpStoreImport',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{
      uploadWeight: this.$kit.api.expStoreImportTw,
      results: [],   //表格数据列表
      fileList: []
    }
  },
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    initForm(){
        this.results.splice(0);
        this.fileList.splice(0);
    },
    handleSuccess(res, file, fileList) {
        if(res.status){
            var datas = res.data;
            datas.forEach(a => this.results.push(a));
        }else{
          this.$message(res.msg || this.msg("上传失败"));
        }
    },
    downloadTpl(){
      window.location.href= this.$kit.api.expStoreDownloadTw;
    }
  }
}
</script>

<style class="scss" scoped>


</style>
