<!-- 台湾库存上架 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('台湾库存上架')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="6">
        <el-input :placeholder="msg('扫描收件單號')" ref="inputer1" @keyup.native="autoSearch" v-model.trim="innerCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="4">
      <el-input :placeholder="msg('架位号')" ref="inputer2" v-model.trim="shelfCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="4">
      收貨數量 <el-input-number v-model="actualQty" controls-position="right" :min="0" :step="1" size="small">
      </el-input-number>
      <!-- <el-input :placeholder="msg('實際數量')" ref="inputer3" v-model.trim="actualQty" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input> -->
    </el-col>
    <el-col :xs="24" :sm="4">
      <el-input :placeholder="msg('備註')" ref="inputer4" v-model.trim="remark" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="4">
      <el-button type="success" @click="upper()" size="mini" style="margin-left: 10px; ">{{msg('上架')}}</el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <template>
    <el-table
      :data="tableData"
      border
      style="width: 80%">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="innerCode" label="收件單號" width="250"></el-table-column>
      <el-table-column prop="shelfCode" label="架位号" width="200"></el-table-column>
      <el-table-column prop="actualQty" label="實際數量" width="200"></el-table-column>
      <el-table-column prop="remark" label="備註" width="200"></el-table-column>
      <el-table-column prop="msg" label="结果"></el-table-column>
    </el-table>
  </template>
  </el-row>

  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
</div> 
</template> 

<script>
// @ is an alias to /src
import ok from "@/assets/media/ok.mp3";
import no from "@/assets/media/no.mp3";
import cfsj from "@/assets/media/cfsj.mp3";
export default {
  name: 'ExpPrintPage',
  components: {
  },
  mounted:function(){
  },
  data(){
    return{
      innerCode: null,
      shelfCode: null,
      actualQty:null,
      remark:null,
      tableData:[],
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    autoSearch(e){

        if(e.keyCode == 13){
            this.upper();
        }

    },

    upper(){

      if(!this.innerCode){
        this.$message(this.msg('请输入单号'));
        return;
      }
      
      this.axios.get(this.$kit.api.expStoreUpper+'?innerCode='+this.innerCode+'&shelfCode='+this.shelfCode+'&actualQty='+this.actualQty+'&remark='+this.remark)
      .then((result) => {
        if(result.status){
          var datas = result.data;
            this.tableData.push({
              innerCode:datas.code,
              shelfCode:datas.shelfCode,
              actualQty:datas.actualQty,
              remark:datas.remark,
              msg:"入库成功",
            })
          if(result.msg == '已存在'){
              this.playAudio(cfsj);
          }else{
              this.playAudio(ok);
          }
        }else{
          this.playAudio(no);
        }
        this.innerCode = null;
        this.actualQty = null;
        this.remark = null;
        this.$refs['inputer1'].focus();
        
      }).catch(function (error) {
        console.error(error);
      });

    },
    playAudio(url){
        let audio = this.$refs.audioRef;
        audio.src = url;
        audio.play();
    },
  }
}
</script>

<style>
.el-col-lg-6 {
    width: 22%;
}
</style>
